import { Language } from '@frond/shared/lib/i18n';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import { LandingPage } from '../modules/marketing/views/LandingPage';

export async function getStaticProps({ locale }: { locale: Language }) {
  return {
    props: {
      version: {
        heroHeading: ['The easiest way to', 'start a', 'community'],
        heroSubHeading:
          'Frond is a refreshingly simple take on forums. Growing your community here is effortless, fun, and free to start.',
      },
      ...(await serverSideTranslations(locale, ['common'])),
    },
  };
}

export default LandingPage;
